import React from 'react'
import { FormControl, InputLabel, NativeSelect } from '@material-ui/core';


const generateOptions = (options, firstNull = false) => {
    let result = [];
    if (firstNull) result.push(<option value="" key="null_element"></option>);
    Object.entries(options).forEach(  ([key, label]) => {
        result.push(<option value={key} key={key}>{label}</option>);
    });
    return result;
}

const FilterSelect = (props) => {
    const options = generateOptions(props.options||{}, true);
    const {label} = props;
    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <NativeSelect
                fullWidth
                {...props}
            >
                {options}
            </NativeSelect>        
        </FormControl>
    );
}

export default FilterSelect;