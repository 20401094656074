import React from 'react'

const RoomTime = (props) => {
    return (
        <ul>
            {props.timetable.map( (row) => (
                <li>{row.day} de {row.from} a {row.to} - Aula {row.room} ({row.building})</li>
            ))}
        </ul>
    );
}

export default RoomTime;