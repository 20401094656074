import React from 'react'
import { Grid, Typography } from '@material-ui/core';

const LastUpdate = (props) => {
    const lastUpdate = props.lastUpdate || 'Nunca';
    return(
        <Grid container>
            <Grid item xs />
            <Grid item>
                <Typography variant="subtitle1">Última actualización: {lastUpdate}</Typography>
            </Grid>
        </Grid>
    );
}

export default LastUpdate;