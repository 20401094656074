import React from 'react'
import { AppBar, Toolbar, Typography, Grid } from '@material-ui/core';
import logo from "../images/logo_front.png";

const Header = (props) => {
    let currentQuarterData = '';
    if (props.currentQuarter) {
        const { quarter, year } = props.currentQuarter;
        if (quarter && year)
            currentQuarterData = ' - ' + quarter + '° cuatrimestre ' + year;
    }

    return (
        <Grid container>
            <AppBar position="static" style={{ background: '#0288D1' }}>
                <Toolbar>
                    <Grid item>
                        <Typography variant="h4">Oferta horaria{currentQuarterData}</Typography>
                    </Grid>
                    <Grid item xs />
                    <Grid item>
                        <img src={logo} alt="Logo" style={{maxHeight: 75}}/>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Grid>
    )
}

export default Header;