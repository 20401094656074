import React from 'react'
import { Grid, Typography, TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import RoomTime from './RoomTime';

const TimeTable = (props) => {
    const {id, description, commisions} = props;
    return (
        <div>
            <Grid container style={{paddingTop: '30px'}}>            
                <Grid item xs justify="center">
                    <Typography variant="h5">{id} - {description}</Typography>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table aria-label="Cursos y horarios">
                    <TableHead>
                        <TableRow>
                            <TableCell>Curso</TableCell>
                            <TableCell>Docentes</TableCell>
                            <TableCell>Vacantes</TableCell>
                            <TableCell>Horario/Aula</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {commisions.map( (row) => (
                            <TableRow key={row.name}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.teachers}</TableCell>
                                <TableCell>{row.vacancy}</TableCell>
                                <TableCell><RoomTime timetable={row.timetable} /></TableCell>
                            </TableRow>
                        )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default TimeTable;