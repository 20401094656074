import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import Header from './components/Header';
import LastUpdate from './components/LastUpdate';
import Filter from './components/Filter';
import axios from 'axios';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { API_DEPARTAMENTOS_URL, API_LAST_UPDATE, API_HORARIOS_URL, API_MATERIAS_URL } from "./config/config.js"
import TimeTable from './components/TimeTable';
import { Fab, Grid } from '@material-ui/core';

const fabStyle = {
  margin: 0,
  top: 'auto',
  right: 20,
  bottom: 20,
  left: 'auto',
  position: 'fixed',
};

class App extends Component {
  constructor (props) {
    super(props);
    this.state={
      deptos: {},
      materias: {},
      materia: null,
      horarios: null,
      lastUpdate: '...',
      currentQuarter: null,
      displayGoTopButton: false,
      loading: true,
    };
  }

  componentDidMount = () => {
    this.loadDepartamentos();
    this.updateLastUpdate();
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    
    if (window.scrollY >= 150 && ! this.state.displayGoTopButton) {
      this.setState({...this.state, displayGoTopButton: true});
    }

    if (window.scrollY < 150 && this.state.displayGoTopButton) {
      this.setState({...this.state, displayGoTopButton: false});
    }
  }

  clearLoadingSign = () => {
    let state = {...this.state};
    state.loading = false;
    this.setState ( state );  
  }

  updateLastUpdate = () => {


    let state = {...this.state};
    state.loading=true;
    this.setState( state, () => {

      axios ({
        url: API_LAST_UPDATE,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
  
      }).then ( (result) => {
        this.setState ({
          lastUpdate: result.data.lastUpdate,
          currentQuarter: result.data.currentQuarter,
        });
  
      }).catch ( (err) => {
        console.log ('Error obteniendo la fecha de última actualización', err);
  
      }).then( (result) => {
        this.clearLoadingSign();
      });
  

    })
  }

 
  loadDepartamentos = () => {

    let state = {...this.state};
    state.loading = true;
    this.setState(state, () => {


      axios ({
        url: API_DEPARTAMENTOS_URL,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
  
      }).then ( (result) => {
        let state = {...this.state};
        state.deptos = result.data;
        state.materia = null;
        state.materias= {};
        state.horarios = null;
        this.setState ( state );
  
      }).catch ( (err) => {
        console.log ('loadDepartamentos error', err);
  
      }).then( (result) => {    
        this.clearLoadingSign();
      });
  

    })
  }

  loadMaterias = (idDepartamento = null) => {

    let state = {...this.state};
    if ( ! idDepartamento) {
      state.materias = {};
      state.materia = null;
      state.horarios = null;
      state.loading = false;
      this.setState ( state );
      return;
    }

    state.loading=true;
    state.materia=null;
    state.materias={};
    this.setState (state, () => {

      axios ({
        url: `${API_MATERIAS_URL}/${idDepartamento}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    
      }).then ( (result) => {    
        let state = {...this.state};
        state.materias = result.data;
        state.materia = null;
        state.horarios = null;
        this.setState ( state );
    
      }).catch ( (err) => {
        console.log ('Error cargando materias del departamento', idDepartamento, err);
    
      }).then( (result) => {
        this.clearLoadingSign();
      });
    })

  }

  loadCursos = (idMateria = null) =>  {

    let state = {...this.state};      
    if ( ! idMateria) {
      state.materia = null;
      state.horarios = null;
      this.setState ( state );
      return;
    }

    state.loading = true;
    this.setState( state , () => {

      axios ({
        url: `${API_HORARIOS_URL}/${idMateria}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
  
      }).then ( (result) => {
        let state = {...this.state};
        state.materia = idMateria;
        state.horarios = result.data;
        this.setState ( state );
    
      }).catch ( (err) => {
        console.log ('Error obteniendo materias', err);
  
      }).then( (result) => {    
        this.clearLoadingSign();
      });
      
    })

  }
  
  reload = () => {
    if (this.state.materia === null) return;
    this.loadCursos(this.state.materia);
    this.updateLastUpdate();
  }

  render = () => {
    return (
      <div>
        <Header currentQuarter={this.state.currentQuarter} />
        <Grid container>
          <Grid item>
            <LastUpdate lastUpdate={this.state.lastUpdate}/>
          </Grid>
          
          <Grid item xs></Grid>
          <Grid item style={{height:'32px'}}>
            {this.state.loading && <ReactLoading type="bars" color="#17a2b8" width="32px" height="32px" /> }
          </Grid>
        </Grid>      
        <Filter 
          departamentos={this.state.deptos}
          materias={this.state.materias}
          
          onDepartamentosChange={this.loadMaterias}
          onMateriasChange={this.loadCursos}

          disableReload={this.state.materia === null}
          onClickReload={this.reload}
        />
        { (!! this.state.horarios) && <TimeTable {...this.state.horarios} /> }
        { this.state.displayGoTopButton && <Fab 
          variant="extended" 
          color="primary" 
          style={fabStyle} 
          onClick={ () => { window.scrollTo(0, 0); } } 
        >
          <KeyboardArrowUpIcon /> Volver
        </Fab>}
        <div style={{height: '65px'}} />
      </div>
    );
  }
}

export default App;
