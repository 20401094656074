import React from 'react'
import { Grid, IconButton } from '@material-ui/core';
import FilterSelect from './FilterSelect';
import ReplayIcon from '@material-ui/icons/Replay';

const Filter = (props) => {

    return (
        <Grid 
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Grid item xs={12} sm={5}>
                <FilterSelect
                    label="Departamento"
                    options={props.departamentos||{}}
                    onChange={ (e) => props.onDepartamentosChange(e.target.value) }
                />
            </Grid>

            <Grid item xs={12} sm={5}>
                <FilterSelect
                    label="Materia"                    
                    options={props.materias}
                    onChange={ (e) => props.onMateriasChange(e.target.value) }
                />
            </Grid>
            <Grid item xs={12} sm={1} >
                <IconButton 
                    color="primary" 
                    disabled={props.disableReload} 
                    aria-label="Recargar" 
                    component="span"
                    title="Recargar cursos"
                    onClick={props.onClickReload}
                >
                    <ReplayIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
}

export default Filter;